import React, { useState, useContext, useEffect } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { IMessage } from 'src/interfaces/IMessage';
import { IPlayer } from 'src/interfaces/IPlayer';

import LinkPreview from './LinkPreview';
import { FirebaseContext } from 'src/components/Firebase';
import { EgTextInput, EgGameH3, EgGameBox } from 'src/components/EgComponents';
import { COLORS } from 'src/styles/Colors';

const ChatBody = styled.div({
  width: '100%',
  height: 300,
  overflow: 'auto',
  padding: '12px 6px',
});

interface Props {
  currentUser: IPlayer;
  gameId: string;
  messages?: { [keyName: string]: IMessage };
}

const ChatBox = (props: Props) => {
  const { currentUser, messages, gameId } = props;
  const [newMessage, setNewMessage] = useState('');
  const { db } = useContext(FirebaseContext);

  useEffect(() => {
    const chatWindowEl = document.querySelector('#chat-window');
    if (chatWindowEl) chatWindowEl.scrollTop = chatWindowEl.scrollHeight;
  }, [messages]);

  const handleEnterMessage = (e) => {
    if (e.keyCode === 13) {
      const newMessageKey = db.ref().push().key;

      const updates = {
        ['/games/' + gameId + '/messages/' + newMessageKey]: {
          messageId: newMessageKey,
          author: currentUser.playerName,
          timestamp: new Date(),
          text: newMessage,
        },
      };

      db.ref().update(updates, (error) => {
        if (error) {
          alert(`Message write failed!`);
        } else {
          setNewMessage('');
        }
      });
    }
  };

  return (
    <EgGameBox direction="column" align="center">
      <div style={{ borderBottom: '3px solid black', textAlign: 'center', width: '100%' }}>
        <EgGameH3 level="3" margin="none" style={{ maxWidth: 'none' }}>
          What's Happening
        </EgGameH3>
      </div>

      <ChatBody id="chat-window">
        {_.map(messages || {}, ({ author, text, timestamp }, key) => {
          let textNode = (
            <span
              style={
                author === 'GAME_BOT'
                  ? {
                      color: COLORS.gameMessageColor,
                      fontStyle: 'italic',
                    }
                  : author === 'GAME_EVENT_PRIORITY'
                  ? {
                      color: COLORS.gameMessageColor,
                      fontWeight: 600,
                    }
                  : {}
              }
            >
              {text}
            </span>
          );
          const formattedDate = new Date(timestamp).toLocaleTimeString();

          const unwrappedGiftMessage = text.match(/(.+ unwrapped gift) \((.+)\)\[(.*)\]/);

          if (unwrappedGiftMessage) {
            const messageFront = unwrappedGiftMessage[1];
            const giftTitle = unwrappedGiftMessage[2];
            const giftUrl = unwrappedGiftMessage[3];

            textNode = (
              <>
                <span>{messageFront}: </span>
                {giftUrl ? (
                  <>
                    <a href={giftUrl} target="_blank" rel="noopener noreferrer">
                      <b>{giftTitle}</b>
                    </a>
                    <LinkPreview url={giftUrl} />
                  </>
                ) : (
                  <b>{giftTitle}</b>
                )}
              </>
            );
          }

          const isGameEvent = author === 'GAME_EVENT' || author === 'GAME_EVENT_PRIORITY';

          return (
            <div key={key} style={{ padding: 6 }}>
              <span
                style={{
                  color:
                    author === 'GAME_BOT' ||
                    author === 'GAME_EVENT' ||
                    author === 'GAME_EVENT_PRIORITY'
                      ? COLORS.gameMessageColor
                      : currentUser.playerName === author
                      ? COLORS.currentPlayerMessageColor
                      : COLORS.otherPlayersMessageColor,
                  fontWeight: 600,
                }}
              >
                [{<span style={{ letterSpacing: 1, fontSize: 16 }}>{formattedDate}</span>}]{' '}
                {author !== 'GAME_BOT' && !isGameEvent ? `${author}: ` : ``}
              </span>

              {textNode}
            </div>
          );
        })}
      </ChatBody>

      <div style={{ padding: 9, width: '100%' }}>
        <EgTextInput
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.currentTarget.value)}
          onKeyDown={handleEnterMessage}
          size="small"
          placeholder="Type your message here and hit enter"
        />
      </div>
    </EgGameBox>
  );
};

export default ChatBox;
