export const COLORS = {
  primaryBrand: '#000',
  focus: 'lightgray',
  lightGreen: '#AAC826',
  darkGreen: '#5C8700',
  floralWhite: '#FAF7EF',
  lightTeal: '#88D3C5',
  ultraLightTeal: '#d4fdf5',
  mediumTeal: '#63A69F',
  darkTeal: '#3E7979',
  /* Colors named by how it is used */
  textFieldBg: 'white',
  inActiveTab: '#EDEDED',
  noteBorder: '#e57e0a',
  highlight: 'lightyellow',
  gameMessageColor: 'black',
  currentPlayerMessageColor: 'black',
  otherPlayersMessageColor: 'black',
};
