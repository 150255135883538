import React from 'react';
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import useForm from '../hooks/useForm';
import { Box, Form, FormField, Tab, Tabs, ThemeContext } from 'grommet';
import { EgButtonFixedWidth, EgFormTextInput, EgBackButton } from 'src/components/EgComponents';
import { Spinner } from 'src/components/Spinner';

const PlayTab = {
  tab: {
    extend: `
      width: 115px;
      text-align: center;
      font-weight: bold;
      border-radius: 10px 0 0 10px;
      border-top-style: solid;
      border-right-style: solid;
      border-bottom-style: solid;
      border-left-style: solid;    
    `,
  },
};

const WatchTab = {
  tab: {
    extend: `
      width: 115px;
      text-align: center;
      font-weight: bold;
      border-radius: 0 10px 10px 0;
      border-top-style: solid;
      border-right-style: solid;
      border-bottom-style: solid;
      border-left-style: none;   
    `,
  },
};

const PlayerInfo = (props: any) => {
  const history = useHistory();

  const initialFormState = {
    playerName: '',
    giftUrl: '',
    giftTitle: '',
    isSpectator: false,
  };

  const { formState, handleTab, handleChange, handleSubmit } = useForm({
    initialFormState,
    onSubmit: props.submitPlayer,
  });

  const [activeIndex, setActiveIndex] = useState(0);

  const onActive = (i) => {
    setActiveIndex(i);
    handleTab(i);
  };

  // Subview: GiftInfo
  let giftInfo = <></>;

  if (!formState.isSpectator) {
    giftInfo = (
      <>
        <FormField label="Title of your gift">
          <EgFormTextInput
            focusIndicator={true}
            size="small"
            placeholder="Super cute pikachu plushie"
            value={formState.giftTitle}
            name="giftTitle"
            onChange={handleChange}
          />
        </FormField>

        <FormField
          label="Link to gift (unwrapped)*"
          margin={{ bottom: 'medium' }}
          error={Boolean(props.hasUrlError) ? `Not a valid URL, try re-entering.` : ''}
        >
          <EgFormTextInput
            focusIndicator={true}
            size="small"
            placeholder="https://www.amazon.com/Pokemon-Pikachu-Plush-Toy-Ultra-Soft/dp/B085QLF44V/"
            value={formState.giftUrl}
            name="giftUrl"
            onChange={handleChange}
          />
          <div style={{ fontSize: '0.8em', lineHeight: 1.7, textAlign: 'right' }}>
            * Optional. See <Link to="/rules">How To Play</Link> for more details!
          </div>
        </FormField>
      </>
    );
  }

  // View
  return (
    <Form id="player-info" onSubmit={handleSubmit} style={{ width: '100%' }}>
      <FormField margin={{ bottom: 'medium' }}>
        <Tabs title="isSpectator" activeIndex={activeIndex} onActive={onActive}>
          <ThemeContext.Extend value={PlayTab}>
            <Tab title="PLAY"></Tab>
          </ThemeContext.Extend>
          <ThemeContext.Extend value={WatchTab}>
            <Tab title="WATCH"></Tab>
          </ThemeContext.Extend>
        </Tabs>
      </FormField>

      <FormField
        label="Name"
        error={
          Boolean(props.hasPlayerError)
            ? `There is already someone named ${formState.playerName}. Please choose another name.`
            : ''
        }
      >
        <EgFormTextInput
          name="playerName"
          focusIndicator={true}
          placeholder="John Doe"
          value={formState.playerName}
          onChange={handleChange}
        />
      </FormField>

      {giftInfo}

      <Box direction="row" justify="center" margin={{ top: 'large', bottom: 'medium' }}>
        {props.isLoading && <Spinner />}

        {!props.isLoading && (
          <Box direction="column">
            <EgButtonFixedWidth
              primary
              type="submit"
              form="player-info"
              label="SUBMIT"
              disabled={
                formState.isSpectator
                  ? formState.playerName === ''
                  : formState.playerName === '' || formState.giftTitle === ''
              }
              style={{ marginBottom: 12 }}
            />
            <EgBackButton secondary type="button" label="Back" onClick={() => history.push('/')} />
          </Box>
        )}
      </Box>
      <div style={{ fontSize: '1em', lineHeight: 1.4, textAlign: 'left' }}>
        <b>NOTE:</b> PLEASE DON'T TROLL. Please put your real name, gift title, and a real gift link (if you have one). Thanks &amp; Have Fun!
      </div>
    </Form>
  );
};

export default PlayerInfo;
