import React from 'react';
import { Box, Heading, Text } from 'grommet';
import { EgButtonFixedWidth } from 'src/components/EgComponents';
import { COLORS } from 'src/styles/Colors';

interface StartGameFunc {
  (): void;
}

interface Props {
  gameId: string;
  isCurrentPlayerHost: boolean;
  playerCount: number;
  startGame: StartGameFunc;
}

const PregameRoomStatus = (props: Props) => {
  const { gameId, isCurrentPlayerHost, playerCount, startGame } = props;

  return (
    <Box direction="column" align="center">
      <Heading level="1" margin="small">
        <div style={{ background: COLORS.floralWhite, padding: '0.2em 0.6em', border: '2px dashed' }}>
          {gameId}
        </div>
      </Heading>

      <Text size="small" style={{ fontStyle: 'italic' }}>
        The game has not yet started.
      </Text>

      {isCurrentPlayerHost && (
        <EgButtonFixedWidth
          primary
          label="Start Game"
          type="button"
          onClick={startGame}
          disabled={playerCount < 3}
          style={{ margin: '0.5em 0' }}
        />
      )}
    </Box>
  );
};

export default PregameRoomStatus;
