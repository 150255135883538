import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from 'grommet';
import PlayerInfo from '../components/PlayerInfo';
import { FirebaseContext } from 'src/components/Firebase';
import { generateLetterCode } from 'src/utility/Utils';
import { EgCappedHeading } from 'src/components/EgComponents';
import { isValidUrl } from 'src/utility/Utils';

interface PlayerFields {
  playerName: string;
  giftUrl?: string;
  giftTitle?: string;
  isSpectator: boolean;
}

const CreateGame = () => {
  const history = useHistory();
  const { db } = useContext(FirebaseContext);
  const [isLoading, setIsLoading] = useState(false);
  const [hasUrlError, setHasUrlError] = useState(false);

  const getNewGameKey = async () => {
    let newGameRefKey = generateLetterCode();
    let isAlreadyUsedCode = (await db.ref('/games/' + newGameRefKey).once('value')).val();

    while (isAlreadyUsedCode) {
      newGameRefKey = generateLetterCode();
      isAlreadyUsedCode = (await db.ref('/games/' + newGameRefKey).once('value')).val();
    }

    return newGameRefKey;
  };

  const submitPlayer = async (playerInfo: PlayerFields) => {
    setIsLoading(true);
    const { playerName, giftUrl, giftTitle, isSpectator } = playerInfo;

    if (giftUrl && !isValidUrl(giftUrl)) {
      setHasUrlError(true);
      setIsLoading(false);
      return;
    }

    const newGiftRefKey = db.ref().push().key;
    const newUserRefKey = db.ref().push().key;
    const newGameRefKey = await getNewGameKey();

    const newMessageRefKey = db.ref().push().key;

    const userData = {
      playerId: newUserRefKey,
      playerName,
      giftId: newGiftRefKey,
      gameId: newGameRefKey,
      isHost: true,
    };

    const giftData = {
      giftId: newGiftRefKey,
      giftUrl,
      giftTitle,
      playerId: newUserRefKey,
      gameId: newGameRefKey,
      steals: 0,
    };

    const newMessage = {
      messageId: newMessageRefKey,
      text: `${playerName} created a new game (${newGameRefKey}) and joined as a ${
        isSpectator ? 'spectator' : 'player'
      }.`,
      author: 'GAME_BOT',
      timestamp: new Date(),
    };

    const updates = {
      ['/users/' + newUserRefKey]: userData,
      ['/games/' + newGameRefKey + '/host']: userData,
      ['/games/' + newGameRefKey + '/status']: 'WAITING_TO_START',
      ['/games/' + newGameRefKey + '/createdOn']: new Date(),
      ['/gifts/' + newGiftRefKey]: giftData,
      ['/games/' + newGameRefKey + '/messages/' + newMessageRefKey]: newMessage,
    };

    if (isSpectator) {
      updates['/games/' + newGameRefKey + '/spectators/' + newUserRefKey] = userData;
    } else {
      updates['/games/' + newGameRefKey + '/players/' + newUserRefKey] = userData;
    }

    db.ref().update(updates, (error) => {
      if (error) {
        alert('Error creating new game');
      } else {
        localStorage.setItem('WHITE_ELEPHANT_USER_ID', newUserRefKey);
        history.push(`/game/${newGameRefKey}`);
      }
      setIsLoading(false);
    });
  };

  return (
    <Box direction="column" align="center" pad="large" style={{ maxWidth: 700, width: '100%' }}>
      <EgCappedHeading level="1" margin="small" style={{ textTransform: 'uppercase' }}>
        Create Room
      </EgCappedHeading>

      <Box direction="row" margin="medium" style={{ width: '100%' }}>
        <PlayerInfo
          submitPlayer={submitPlayer}
          hasPlayerError={false}
          hasUrlError={hasUrlError}
          isLoading={isLoading}
        />
      </Box>
    </Box>
  );
};

export default CreateGame;
