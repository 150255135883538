import React, { useContext, useState } from 'react';
import _ from 'lodash';
import { ModalProvider } from 'styled-react-modal';
import { Box } from 'grommet';

import UnwrapGiftModal from './UnwrapGiftModal';
import { FirebaseContext } from 'src/components/Firebase';
import GiftsPile from 'src/components/GiftsPile';
import { ChristmasTreeIcon } from 'src/components/ChristmasTreeIcon';
import { GIFTS_CONFIGS } from 'src/utility/Utils';
import { IPlayer } from 'src/interfaces/IPlayer';
import { IGift } from 'src/interfaces/IGift';
import { COLORS } from 'src/styles/Colors';

interface PlayerNameFunc {
  (string): string;
}
interface Props {
  gameId: string;
  playersOrderedByTurn: string[];
  turnIndex: number;
  currentUser: IPlayer;
  giftPile: string[];
  currentTurnPlayer: IPlayer;
  doesTurnBelongToCurrentUser: boolean;
  unwrappedGifts: { [keyName: string]: IGift };
  getPlayerNameById: PlayerNameFunc;
}

const formatAndSortGifts = (giftPile: string[], unwrappedGifts: { [keyName: string]: IGift }) => {
  const unwrappedGiftsArr = _.map(unwrappedGifts, (_NOT_USED, giftId) => ({ giftId }));
  const giftPileGifts = giftPile.map((giftId) => ({ giftId }));

  const allGifts = giftPileGifts.concat(unwrappedGiftsArr);

  const sortedAllGifts = _.sortBy(allGifts, 'giftId');

  return sortedAllGifts.map(({ giftId }, i) => ({
    giftId,
    ...GIFTS_CONFIGS[i % GIFTS_CONFIGS.length],
  }));
};

const LiveSession = (props: Props) => {
  const {
    gameId,
    playersOrderedByTurn,
    turnIndex,
    currentUser,
    giftPile,
    currentTurnPlayer,
    doesTurnBelongToCurrentUser,
    unwrappedGifts,
    getPlayerNameById,
  } = props;

  const ModalConstants = {
    title: 'Are you sure you want to pick this gift?',
    primaryButtonLabel: 'YES!',
  };

  const { db } = useContext(FirebaseContext);
  const [shouldOpenModal, setShouldOpenModal] = useState(false);
  const [selectedGiftId, setSelectedGiftId] = useState('');
  const [modalTitle, setModalTitle] = useState(ModalConstants.title);

  const postMessage = (message: string, author: string = 'GAME_EVENT') => {
    const newMessageKey = db.ref().push().key;

    const updates = {
      ['/games/' + gameId + '/messages/' + newMessageKey]: {
        messageId: newMessageKey,
        author: author,
        timestamp: new Date(),
        text: message,
      },
    };

    db.ref().update(updates, (error) => {
      if (error) {
        alert(`Message write failed!`);
      }
    });
  };

  const hostOverrideTag =
    currentTurnPlayer.playerId !== currentUser.playerId ? '(HOST OVERRIDE ACTION) ' : '';

  const cancelModal = () => {
    setShouldOpenModal(false);
    postMessage(
      `${hostOverrideTag}${currentTurnPlayer.playerName} decided to look for something else.`,
    );
  };

  const selectGift = async (giftId) => {
    if (!doesTurnBelongToCurrentUser && !currentUser.isHost) return;

    setSelectedGiftId(giftId);
    setShouldOpenModal(true);
    postMessage(
      `${hostOverrideTag}${currentTurnPlayer.playerName} is eyeing a gift from the gift pile!`,
    );

    const didCurrentUserBringGift = currentUser.giftId === giftId;
    let confirmationMessage = ModalConstants.title;
    if (didCurrentUserBringGift) {
      confirmationMessage += ' Heads up that this gift is YOUR OWN gift!';
    }
    setModalTitle(confirmationMessage);
  };

  const persistGiftSelection = async (giftId) => {
    // Close modal
    setShouldOpenModal(false);

    // Update Op 1: Remove the gift from the gift pile by overwriting the giftPile
    // with the target gift excluded
    const updateGiftPile = {
      [`/games/${gameId}/giftPile`]: giftPile.filter((id) => id !== giftId),
    };

    // Update Op 2: Mark that the current turn player now owns that gift
    const giveCurrentUserSelectedGift = {
      [`/games/${gameId}/players/${currentTurnPlayer.playerId}/gottenGiftId`]: giftId,
    };

    // Update Op 3: Get gift details and add to unwrapped gifts with steal count 0
    const giftSnapshot = await db.ref('/gifts/' + giftId).once('value');
    const giftData = giftSnapshot.val();

    const addGiftToUnwrappedGifts = {
      [`/games/${gameId}/unwrappedGifts/${giftId}`]: {
        ...giftData,
        steals: 0,
      },
    };

    // Update Op 4: Report who unwrapped what in the game messages box
    const newMessageRefKey = db.ref().push().key;
    const hostOverrideTag =
      currentTurnPlayer.playerId !== currentUser.playerId ? '(HOST OVERRIDE ACTION) ' : '';

    const postMessageForUnwrap = {
      [`/games/${gameId}/messages/${newMessageRefKey}`]: {
        timestamp: new Date(),
        messageId: newMessageRefKey,
        text: `${hostOverrideTag}${
          currentTurnPlayer.playerName
        } unwrapped gift (${giftData.giftTitle.toUpperCase()})[${giftData.giftUrl}]`,
        author: 'GAME_EVENT_PRIORITY',
      },
    };

    // Update Op 5: Move onto the next turn
    const newTurnIndex = turnIndex + 1;
    const incrementTurnIndex = {
      [`/games/${gameId}/turnIndex`]: newTurnIndex,
    };

    // Update Op 6: Set game status to 'IN_PROGRESS' unless it's the last turn
    // since this move might signal the end of an ongoing steal round; if it's the last
    // turn, then set 'FINAL_ROUND'
    const gameStatusTag =
      newTurnIndex === playersOrderedByTurn.length ? 'FINAL_ROUND' : 'IN_PROGRESS';
    const setGameStatus = {
      [`/games/${gameId}/status`]: gameStatusTag,
    };

    // Update Op 6.b: Announce the new turn or the final round.
    // depending on if it's the FINAL_ROUND or IN_PROGRESS first.

    let turnAnnouncement = '';
    switch (gameStatusTag) {
      case 'IN_PROGRESS':
        const playerWithTurn = getPlayerNameById(playersOrderedByTurn[newTurnIndex]);
        turnAnnouncement = `Now it's player #${newTurnIndex + 1} ${playerWithTurn}'s turn!`;
        break;
      case 'FINAL_ROUND':
        const playerNumberOne = getPlayerNameById(playersOrderedByTurn[0]);
        turnAnnouncement = `Final round commencing with Player #1: ${playerNumberOne} gets to choose whether to steal... or keep their current gift. `;
        break;
    }
    const newRefKeyForTurn = db.ref().push().key;
    const postMessageForTurnUpdate = {
      [`/games/${gameId}/messages/${newRefKeyForTurn}`]: {
        timestamp: new Date(),
        messageId: newRefKeyForTurn,
        text: turnAnnouncement,
        author: 'GAME_EVENT_PRIORITY',
      },
    };

    // Update Op 7: Remove previousOwnerId from all unwrappedGifts to reset them
    // for the next potential steal round
    const blankOutPreviousOwnerFromAllUnwrappedGifts = _.reduce(
      unwrappedGifts,
      (acc, { previousOwnerId, ...gift }, localGiftId) => {
        acc[`/games/${gameId}/unwrappedGifts/${localGiftId}`] = gift;
        return acc;
      },
      {},
    );

    // Update Op 8: Just in case currentTurnPlayer was just stolen from, null out that
    // property now that a gift has been selected from giftPile; may be a no-op
    const nullifyPlayerGiftStolenStatus = {
      [`/games/${gameId}/players/${currentTurnPlayer.playerId}/wasJustStolenFrom`]: null,
    };

    db.ref().update(
      {
        ...updateGiftPile,
        ...giveCurrentUserSelectedGift,
        ...addGiftToUnwrappedGifts,
        ...postMessageForUnwrap,
        ...incrementTurnIndex,
        ...setGameStatus,
        ...postMessageForTurnUpdate,
        ...blankOutPreviousOwnerFromAllUnwrappedGifts,
        ...nullifyPlayerGiftStolenStatus,
      },
      (error) => {
        if (error) alert('Move failed! ' + error);
      },
    );
  };

  const formattedGifts = formatAndSortGifts(giftPile, unwrappedGifts);

  return (
    <Box direction="column" align="center" gap="small">
      <ChristmasTreeIcon />
      <p style={{ fontSize: 13.5, fontWeight: 'bold' }}>
        Heads up: Gift with the <span style={{ color: COLORS.mediumTeal }}>teal border</span> is yours!
      </p>
      <GiftsPile
        shouldDisableClick={!doesTurnBelongToCurrentUser && !currentUser.isHost}
        gifts={formattedGifts}
        unwrappedGifts={unwrappedGifts}
        handleGiftClick={({ giftId }) => selectGift(giftId)}
        currentUser={currentUser}
      />

      <ModalProvider>
        <UnwrapGiftModal
          isOpen={shouldOpenModal}
          currentUser={currentUser}
          giftId={selectedGiftId}
          titleText={modalTitle}
          primaryButtonLabel={ModalConstants.primaryButtonLabel}
          cancelAction={cancelModal}
          primaryAction={persistGiftSelection}
        />
      </ModalProvider>
    </Box>
  );
};

export default LiveSession;
