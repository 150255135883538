import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from 'grommet';
import _ from 'lodash';

import EnterRoom from 'src/components/EnterRoom';
import PlayerInfo from 'src/components/PlayerInfo';
import { FirebaseContext } from 'src/components/Firebase';
import { EgCappedHeading } from 'src/components/EgComponents';
import { isValidUrl } from 'src/utility/Utils';

interface PlayerFields {
  playerName: string;
  giftUrl?: string;
  giftTitle?: string;
  isSpectator: boolean;
}

const JoinGame = () => {
  const history = useHistory();
  const { db } = useContext(FirebaseContext);
  const [isRoomEntered, setIsRoomEntered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasRoomError, setHasRoomError] = useState(false);
  const [gameId, setGameId] = useState('');
  const [hasPlayerError, setHasPlayerError] = useState(false);
  const [hasUrlError, setHasUrlError] = useState(false);

  const submitRoom = async ({ roomCode }) => {
    roomCode = roomCode.toUpperCase();
    setHasRoomError(false);
    setIsLoading(true);
    const gameSnapshot = await db.ref('/games/' + roomCode).once('value');
    const game = gameSnapshot.val();

    if (!game) {
      setHasRoomError(true);
      setIsLoading(false);
      return;
    }

    setGameId(roomCode);
    setIsRoomEntered(true);
    setIsLoading(false);
  };

  const isDuplicatePlayerName = async (submittedName: string): Promise<boolean> => {
    const gameSnapshot = await db.ref('/games/' + gameId).once('value');
    const game = gameSnapshot.val();
    const gameParticipants = _.merge(game.players, game.spectators);
    const existingNames = _.map(gameParticipants, ({ playerName }, _) => {
      return playerName.toLowerCase();
    });

    return _.includes(existingNames, submittedName.toLowerCase());
  };

  const submitPlayer = async (playerInfo: PlayerFields) => {
    setIsLoading(true);
    setHasPlayerError(false);
    const { playerName, giftUrl, giftTitle, isSpectator } = playerInfo;

    // 1. Check for duplicate player name.
    const isDuplicate = await isDuplicatePlayerName(playerName);
    if (isDuplicate) {
      setHasPlayerError(true);
      setIsLoading(false);
      return;
    }

    // 2. Check for invalid URL.
    if (giftUrl && !isValidUrl(giftUrl)) {
      setHasUrlError(true);
      setIsLoading(false);
      return;
    }

    // 3. Check for current game status.
    const currentGame = (await db.ref('/games/' + gameId).once('value')).val();
    const currentGameStatus = currentGame.status;

    if (currentGameStatus !== 'WAITING_TO_START') {
      if (window.confirm(`Sorry, the game has already started! Press OK to join as a spectator.`)) {
        const newUserRefKey = db.ref().push().key;
        const newMessageRefKey = db.ref().push().key;

        const userData = {
          playerId: newUserRefKey,
          playerName,
          giftId: null,
          gameId: gameId,
        };

        const newMessage = {
          messageId: newMessageRefKey,
          text: `${playerName} joined the game as a spectator.`,
          author: 'GAME_BOT',
          timestamp: new Date(),
        };

        const updates = {
          ['/users/' + newUserRefKey]: userData,
          ['/games/' + gameId + '/messages/' + newMessageRefKey]: newMessage,
        };

        updates['/games/' + gameId + '/spectators/' + newUserRefKey] = userData;

        db.ref().update(updates, (error) => {
          if (error) {
            alert('Error joining existing game');
          } else {
            localStorage.setItem('WHITE_ELEPHANT_USER_ID', newUserRefKey);
            history.push(`/game/${gameId}`);
          }
          setIsLoading(false);
        });
      }

      setIsLoading(false);
      return;
    }

    // 4. Commence joining game
    const newGiftRefKey = db.ref().push().key;
    const newUserRefKey = db.ref().push().key;
    const newMessageRefKey = db.ref().push().key;

    const userData = {
      playerId: newUserRefKey,
      playerName,
      giftId: newGiftRefKey,
      gameId: gameId,
    };

    const giftData = {
      giftId: newGiftRefKey,
      giftUrl,
      giftTitle,
      playerId: newUserRefKey,
      gameId: gameId,
      steals: 0,
    };

    const newMessage = {
      messageId: newMessageRefKey,
      text: `${playerName} joined the game as a ${isSpectator ? 'spectator' : 'player'}.`,
      author: 'GAME_BOT',
      timestamp: new Date(),
    };

    const updates = {
      ['/users/' + newUserRefKey]: userData,
      ['/gifts/' + newGiftRefKey]: giftData,
      ['/games/' + gameId + '/messages/' + newMessageRefKey]: newMessage,
    };

    if (isSpectator) {
      updates['/games/' + gameId + '/spectators/' + newUserRefKey] = userData;
    } else {
      updates['/games/' + gameId + '/players/' + newUserRefKey] = userData;
    }

    db.ref().update(updates, (error) => {
      if (error) {
        alert('Error joining existing game');
      } else {
        localStorage.setItem('WHITE_ELEPHANT_USER_ID', newUserRefKey);
        history.push(`/game/${gameId}`);
      }
      setIsLoading(false);
    });
  };

  return (
    <Box direction="column" align="center" pad="large" style={{ maxWidth: 700, width: '100%' }}>
      <EgCappedHeading level="1" margin="small">
        Join
      </EgCappedHeading>
      {!isRoomEntered ? (
        <EnterRoom submitRoom={submitRoom} hasRoomError={hasRoomError} isLoading={isLoading} />
      ) : (
        <PlayerInfo
          submitPlayer={submitPlayer}
          hasPlayerError={hasPlayerError}
          hasUrlError={hasUrlError}
          isLoading={isLoading}
          style={{ width: '100%' }}
        />
      )}
    </Box>
  );
};

export default JoinGame;
