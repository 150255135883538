import React from 'react';
import { Grommet, Box } from 'grommet';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import Landing from './pages/Landing';
import CreateGame from './pages/CreateGame';
import JoinGame from './pages/JoinGame';
import Game from './pages/Game';
import HowToPlay from './pages/HowToPlay';
import Firebase, { FirebaseContext } from 'src/components/Firebase';
import { COLORS } from './styles/Colors';

const theme = {
  global: {
    font: {
      size: '18px',
      height: '20px',
    },
    colors: {
      brand: COLORS.primaryBrand,
      focus: 'none',
    },
  },
  formField: {
    border: {
      color: 'transparent',
    },
  },
  tab: {
    background: COLORS.inActiveTab,
    active: {
      background: 'white',
    },
    pad: {
      vertical: '8px',
    },
    border: { 
      color: 'black', 
    },
    margin: 'none',
  },
};

function App() {
  return (
    <FirebaseContext.Provider value={new Firebase()}>
      <Grommet theme={theme} style={{ height: '100%', width: '100%' }}>
        <Box align="center" style={{ height: 'auto', width: '100%' }}>
          <Router>
            <Switch>
              <Route exact path="/" render={Landing} />
              <Route path="/create" component={CreateGame} />
              <Route path="/join" component={JoinGame} />
              <Route path="/game/:gameId" component={Game} />
              <Route path="/rules" component={HowToPlay} />
            </Switch>
          </Router>
        </Box>
      </Grommet>
    </FirebaseContext.Provider>
  );
}

export default App;
