import React from 'react';
import { Box } from 'grommet';
import { Link } from 'react-router-dom';
import { EgButtonFixedWidth } from '../components/EgComponents';

import { ReactComponent as LogoSvg } from '../assets/white-elephant-2020-logo.svg';

const Landing = () => {
  return (
    <>
      <div style={{ width: '100%', maxWidth: 600, marginTop: '20px' }}>
        <LogoSvg width="100%" height="100%" style={{ position: 'relative', left: 23 }} />
      </div>

      <Box direction="column" align="center">
        <Box direction="column" margin="medium">
          <Link to="/join">
            <EgButtonFixedWidth label="JOIN ROOM" margin="small" />
          </Link>

          <Link to="/create">
            <EgButtonFixedWidth label="CREATE ROOM" margin="small" />
          </Link>

          <Link to="/rules">
            <EgButtonFixedWidth label="HOW TO PLAY" margin="small" />
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default Landing;
