import React from 'react';
import _ from 'lodash';
import { Box } from 'grommet';

import styled from 'styled-components';

const PlayerListItem = styled.li({
  padding: '2px',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
});

const YourRoomContainer = styled(Box)({
  border: '3px solid black',
  maxWidth: 400,
  width: '100%',
  borderRadius: '10px',
});

const YourRoomTitle = styled.div({
  fontWeight: 'bold',
  fontSize: '1em',
  padding: '5px 10px',
  borderBottom: '3px solid black',
  textAlign: 'center',
});

const YourRoomBodyList = styled.div({
  width: '100%',
  height: 160,
  overflow: 'auto',
  padding: '5px 10px',
});

const WaitingRoom = ({ players = {}, spectators = {} }) => {
  const numPlayers = Object.keys(players).length;
  const numSpectators = Object.keys(spectators).length;

  return (
    <Box direction="column" align="center">
      <YourRoomContainer margin="medium">
        <YourRoomTitle>
          <div>Your Room</div>
          <div style={{ fontSize: 12 }}>
            Players: {numPlayers}, Spectators: {numSpectators}
          </div>
        </YourRoomTitle>
        <YourRoomBodyList>
          <ul
            style={{
              listStyle: 'none',
              paddingInline: 0,
              marginBlock: 0,
              marginInline: 0,
              padding: 0,
              margin: 0,
            }}
          >
            {_.map(players, ({ playerName, isHost }, key) => (
              <PlayerListItem key={key}>
                {playerName} (player) {isHost && `(host)`}
              </PlayerListItem>
            ))}
            {_.map(spectators, ({ playerName, isHost }, key) => (
              <PlayerListItem key={key}>
                {playerName} (spectator) {isHost && `(host)`}
              </PlayerListItem>
            ))}
          </ul>
        </YourRoomBodyList>
      </YourRoomContainer>
    </Box>
  );
};

export default WaitingRoom;
