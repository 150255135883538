import { useState } from 'react';

interface FormState {
  [keyName: string]: any;
}
type SubmitCallBack = (event: any) => void;
type FormParams = { initialFormState: FormState; onSubmit: SubmitCallBack };

const useCustomForm = (formParams: FormParams) => {
  const { initialFormState, onSubmit } = formParams;
  const [formState, setFormState] = useState(initialFormState || {});

  const handleTab = (value) => {
    if (value === 1) value = true;
    if (value === 0) value = false;

    setFormState({ ...formState, isSpectator: value });
  };

  const handleChange = (event: any) => {
    const { target } = event;
    let { name, value } = target;

    if (value === 'true') value = true;
    if (value === 'false') value = false;

    event.persist();
    setFormState({ ...formState, [name]: value });
  };

  const handleSubmit = (event: any) => {
    if (event) event.preventDefault();
    onSubmit(formState);
  };

  return {
    formState,
    handleTab,
    handleChange,
    handleSubmit,
  };
};

export default useCustomForm;
