import React, { useContext } from 'react';
import { ModalProvider } from 'styled-react-modal';
import styled from 'styled-components';
import _ from 'lodash';
import { Box, Text } from 'grommet';

import StealGiftModal from './StealGiftModal';
import { FirebaseContext } from 'src/components/Firebase';
import { EgGameH3, EgGameBox } from 'src/components/EgComponents';
import { IGift } from 'src/interfaces/IGift';
import { COLORS } from 'src/styles/Colors';

const Table = styled.table({
  borderCollapse: 'collapse',
});

const Th = styled.th({
  padding: '6px 12px',
  fontWeight: 'bold',
  textAlign: 'left',
  borderTop: '3px solid black',
  textTransform: 'uppercase',
  color: 'grey',
  fontSize: '1.1em',
});

const Tr = styled.tr({});

const Td = styled.td({
  padding: '6px 12px',
  wordWrap: 'break-word',
  wordBreak: 'break-word',
});

interface GiftInfoProps {
  gift: IGift;
  playerName: string;
  playerId: string;
}

const WhosGotWhat = ({
  gameId,
  players,
  unwrappedGifts,
  playersOrderedByTurn,
  doesTurnBelongToCurrentUser,
  currentUser,
  status,
}) => {
  const { db } = useContext(FirebaseContext);

  // filter players down by who's gotten gifts so far, and sort
  // by turn order
  const playersWhoGotGifts = _.sortBy(
    _.filter(players, ({ gottenGiftId }) => gottenGiftId),
    ({ playerId }) => playersOrderedByTurn.findIndex((id) => id === playerId),
  );

  if (_.isEmpty(playersWhoGotGifts)) {
    return (
      <EgGameBox direction="column" align="center" style={{ paddingBottom: '10px' }}>
        <EgGameH3 level="3" margin="none">
          Who's Got What
        </EgGameH3>
        <Box direction="row" align="center">
          <Text style={{ marginRight: 12 }}>No one has gotten any gifts yet</Text>
          <span role="img" aria-label="Gift icon">
            🎁
          </span>
        </Box>
      </EgGameBox>
    );
  }

  const postMessage = (message: string, author: string = 'GAME_EVENT') => {
    const newMessageKey = db.ref().push().key;

    const updates = {
      ['/games/' + gameId + '/messages/' + newMessageKey]: {
        messageId: newMessageKey,
        author: author,
        timestamp: new Date(),
        text: message,
      },
    };

    db.ref().update(updates, (error) => {
      if (error) {
        alert(`Message write failed!`);
      }
    });
  };

  const stealGift = (giftInfoProps: GiftInfoProps) => {
    const { gift, playerName, playerId } = giftInfoProps;
    const { giftId, steals, previousOwnerId } = gift;

    if (steals === 3) {
      alert(`Gift has been stolen three times already! Max steals reached`);
      postMessage(
        `${
          currentUser.playerName
        } tried to steal ${gift.giftTitle?.toUpperCase()} but it's already locked from max steals.`,
      );
      return;
    } else if (previousOwnerId === currentUser.playerId) {
      alert(`No steal backs allowed!`);
      postMessage(
        `${
          currentUser.playerName
        } attempted to steal ${gift.giftTitle?.toUpperCase()} but no stealbacks allowed!`,
      );
      return;
    }

    // Update Op 1: Does the current user have a gift? If so, swap gifts because
    // that means we've entered the final round. Otherwise null out the
    // the imminent victim's gottenGiftId. Null out the current user's wasJustStolenFrom property
    // and mark the new victim with wasJustStolenFrom: true.
    const currentUserObjWithData = players[currentUser.playerId];

    const takeGiftFromPreviousOwner = {
      [`/games/${gameId}/players/${playerId}/gottenGiftId`]:
        currentUserObjWithData.gottenGiftId || null,
      [`/games/${gameId}/players/${playerId}/wasJustStolenFrom`]: true,
      [`/games/${gameId}/players/${currentUser.playerId}/wasJustStolenFrom`]: null,
    };

    // Update Op 2: Mark that the current user now owns the selected gift
    const giveCurrentUserGift = {
      [`/games/${gameId}/players/${currentUser.playerId}/gottenGiftId`]: giftId,
    };

    // Update Op 3: Increment steals count on gift in the unwrapped gifts pile
    // and add previousOwnerId to prevent steal backs
    const updateGiftStealsAndPreviousOwner = {
      [`/games/${gameId}/unwrappedGifts/${giftId}`]: {
        ...gift,
        steals: steals + 1,
        previousOwnerId: playerId,
      },
    };

    // Update Op 4: Report the steal in the game messages box
    const newMessageKey = db.ref().push().key;

    let swappedMessage = '';
    if (currentUserObjWithData.gottenGiftId) {
      swappedMessage = ` and swapped it with their ${unwrappedGifts[
        currentUserObjWithData.gottenGiftId
      ].giftTitle.toUpperCase()}`;
    }

    const postMessageForSteal = {
      ['/games/' + gameId + '/messages/' + newMessageKey]: {
        messageId: newMessageKey,
        author: 'GAME_EVENT_PRIORITY',
        timestamp: new Date(),
        text: `${
          currentUser.playerName
        } stole ${gift?.giftTitle?.toUpperCase()} from ${playerName}${swappedMessage}!`,
      },
    };

    // Update Op 5: Mark game status as STEAL_ROUND unless it's the FINAL_ROUND
    let markStealRound = {};
    if (status !== 'FINAL_ROUND') {
      markStealRound = { ['/games/' + gameId + '/status']: 'STEAL_ROUND' };
    }

    // Update Ob 6: Report who has the next turn or if it's final round
    const newMessageKeyForTurn = db.ref().push().key;
    const postMessageForTurn = {
      ['/games/' + gameId + '/messages/' + newMessageKeyForTurn]: {
        messageId: newMessageKeyForTurn,
        author: 'GAME_EVENT',
        timestamp: new Date(),
        text:
          status === 'FINAL_ROUND'
            ? `${playerName} now has the choice to keep their current gift or exchange it by stealing.`
            : `Now it's ${playerName}'s turn to choose a gift from the pile or steal.`,
      },
    };

    db.ref().update(
      {
        ...takeGiftFromPreviousOwner,
        ...giveCurrentUserGift,
        ...updateGiftStealsAndPreviousOwner,
        ...postMessageForSteal,
        ...markStealRound,
        ...postMessageForTurn,
      },
      (error) => {
        if (error) alert(`Steal operation failed! Error: ${error}`);
      },
    );
  };

  const postMessageEyeingGift = (playerName: string, giftTitle: string) => {
    postMessage(`${currentUser.playerName} is eyeing ${playerName}'s ${giftTitle}!`);
  };

  const postMessageDropGift = (playerName: string, giftTitle: string) => {
    postMessage(`${currentUser.playerName} put ${playerName}'s ${giftTitle} back down.`);
  };

  return (
    <EgGameBox direction="column" align="center" style={{ paddingBottom: '10px' }}>
      <EgGameH3 level="3" margin="none">
        Who's Got What!
      </EgGameH3>
      <Table style={{ width: '100%' }}>
        <thead>
          <Tr>
            <Th>Player</Th>
            <Th>Has Gift</Th>
            <Th># of Steals</Th>
            <Th></Th>
          </Tr>
        </thead>
        <tbody>
          {playersWhoGotGifts.map(({ playerId, playerName, gottenGiftId }) => {
            const giftData =
              !_.isEmpty(unwrappedGifts) && gottenGiftId && unwrappedGifts[gottenGiftId];

            return (
              <Tr key={gottenGiftId}>
                <Td>
                  {currentUser.playerId === playerId ? (
                    <b style={{ color: COLORS.mediumTeal }}>{playerName}</b>
                  ) : (
                    <span>{playerName}</span>
                  )}
                </Td>
                <Td>
                  {giftData && (
                    <>
                      <span style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
                        {giftData.giftTitle}{' '}
                      </span>
                      {giftData.giftUrl && (
                        <a
                          href={giftData.giftUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: 'none' }}
                        >
                          [link]
                        </a>
                      )}
                    </>
                  )}
                </Td>
                <Td>
                  {giftData && giftData.steals} {giftData.steals === 3 ? '🔒' : ''}
                </Td>
                {doesTurnBelongToCurrentUser && (
                  <Td>
                    <ModalProvider>
                      <StealGiftModal
                        currentUser={currentUser}
                        playerName={playerName}
                        playerId={playerId}
                        gift={giftData}
                        openAction={postMessageEyeingGift}
                        cancelAction={postMessageDropGift}
                        primaryAction={stealGift}
                      />
                    </ModalProvider>
                  </Td>
                )}
              </Tr>
            );
          })}
        </tbody>
      </Table>
    </EgGameBox>
  );
};

export default WhosGotWhat;
