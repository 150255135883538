import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { FirebaseContext } from 'src/components/Firebase';
import WaitingRoom from './WaitingRoom';
import PregameRoomStatus from './PregameRoomStatus';
import LiveSession from './LiveSession';
import ChatBox from './ChatBox';
import WhosGotWhat from './WhosGotWhat';
import FinalGiftStandings from './FinalGiftStandings';
import { IPlayer } from 'src/interfaces/IPlayer';
import { IGameState } from 'src/interfaces/IGameState';
import { ChristmasTreeIcon } from 'src/components/ChristmasTreeIcon';
import { COLORS } from 'src/styles/Colors';
import { EgButton, EgStickyBox, EgCappedHeading } from 'src/components/EgComponents';

import { Box, Heading } from 'grommet';

interface ParamTypes {
  gameId: string;
}

const TurnHeading = styled(Heading)({});

const GameContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: 32,
  paddingRight: 20,
  paddingLeft: 20,
  paddingBottom: 32,

  '@media (max-width: 768px)': {
    flexWrap: 'wrap',
  },
});

const GiftPile = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  flexShrink: 0,
  maxWidth: '300px',
  width: '100%',
  marginRight: '26px',

  '@media (max-width: 768px)': {
    order: 3,
  },
});

const PlayerStatus = styled.div({
  padding: '6px 0 0 0',
  color: COLORS.darkTeal,
  textAlign: 'center',
  lineHeight: 1.2,
});

interface PlayerProps {
  isSpectator: boolean;
  isHost: boolean;
  name: string;
  turnNumber: number;
  playerCount: number;
}

const PlayerStatusView = (props: PlayerProps) => {
  const { isSpectator, isHost, name, turnNumber, playerCount } = props;

  if (isSpectator) {
    return <PlayerStatus>{name}, you are spectating</PlayerStatus>;
  } else {
    return (
      <>
        <PlayerStatus>
          <div>
            {name}, you are #{turnNumber} out of {playerCount} players
          </div>
          <div>{isHost && `You are the host of the game.`}</div>
        </PlayerStatus>
        <section
          style={{
            color: '#CC0000',
            marginTop: 10,
            fontSize: '0.8em',
            lineHeight: 'normal',
            textAlign: 'center',
            maxWidth: 1000,
          }}
        >
          {isHost &&
            `* CAREFUL: You have the power to choose gifts from the pile in the event you encounter idle or disconnected players; Use it wisely!`}
        </section>
      </>
    );
  }
};

const Game = () => {
  const [gameState, setGameState] = useState<IGameState>();
  const [currentUser, setCurrentUser] = useState<IPlayer>();
  const { db } = useContext(FirebaseContext);
  const { gameId } = useParams<ParamTypes>();
  const userId = localStorage['WHITE_ELEPHANT_USER_ID'];

  useEffect(() => {
    db.ref('/games/' + gameId).on('value', (snapshot) => {
      setGameState(snapshot.val());
    });

    db.ref('/users/' + userId).on('value', (snapshot) => {
      setCurrentUser(snapshot.val());
    });
  }, []);

  const startGame = () => {
    if (!gameState) {
      alert(`Attempting to start game failed!`);
      return;
    }
    const { players } = gameState;

    const shuffledTurnOrder = _.shuffle(players).map(({ playerId }) => playerId);
    const shuffledGifts = _.shuffle(players).map(({ giftId }) => giftId);

    const newMessageKey = db.ref().push().key;
    const postMessage = {
      ['/games/' + gameId + '/messages/' + newMessageKey]: {
        messageId: newMessageKey,
        author: 'GAME_EVENT_PRIORITY',
        timestamp: new Date(),
        text: `${currentUser?.playerName} has started the game!!!!`,
      },
    };
    const updates = {
      ['/games/' + gameId + '/status']: 'IN_PROGRESS',
      ['/games/' + gameId + '/giftPile']: shuffledGifts,
      ['/games/' + gameId + '/playersOrderedByTurn']: shuffledTurnOrder,
      ['/games/' + gameId + '/turnIndex']: 0,
      ...postMessage,
    };

    db.ref().update(updates, (error) => {
      if (error) {
        alert(`Attempting to start game failed!`);
      }
    });
  };

  const endGame = () => {
    const newMessageKey = db.ref().push().key;
    const postMessageForEndGame = {
      ['/games/' + gameId + '/messages/' + newMessageKey]: {
        messageId: newMessageKey,
        author: 'GAME_EVENT',
        timestamp: new Date(),
        text: `${currentTurnPlayer.playerName} decided to keep their gift and not to steal...`,
      },
    };
    db.ref().update(
      {
        ...postMessageForEndGame,
      },
      (error) => {
        if (error) alert('Ending game failed! ' + error);
      },
    );

    setTimeout(() => {
      const newKey = db.ref().push().key;
      const postMessageForGameOver = {
        ['/games/' + gameId + '/messages/' + newKey]: {
          messageId: newKey,
          author: 'GAME_EVENT_PRIORITY',
          timestamp: new Date(),
          text: `The game has ended! Check the final gift standings and message the recipient of your gift to confirm how you will send it.`,
        },
      };
      db.ref().update(
        {
          [`/games/${gameId}/status`]: 'GAME_OVER',
          ...postMessageForGameOver,
        },
        (error) => {
          if (error) alert('Ending game failed! ' + error);
        },
      );
    }, 2000);
  };

  const getPlayerNameById = (id: string): string => {
    return players[id].playerName;
  };

  if (!gameState || !currentUser) {
    return (
      <Box direction="column" align="center" pad="large">
        Loading...
      </Box>
    );
  }

  const {
    host,
    players = {},
    spectators,
    status,
    messages,
    giftPile = [],
    playersOrderedByTurn = [],
    turnIndex = 0,
    unwrappedGifts = {},
  } = gameState;

  let currentTurnPlayer;

  switch (status) {
    case 'STEAL_ROUND':
    case 'FINAL_ROUND':
      currentTurnPlayer = _.find(players, ({ wasJustStolenFrom }) => wasJustStolenFrom);

      // if no one was just stolen from at this point, that means we're at the beginning of
      // the final round and the first player now chooses between steal or end the game
      if (!currentTurnPlayer) {
        currentTurnPlayer = _.find(players, ({ playerId }) => playerId === playersOrderedByTurn[0]);
      }
      break;
    default:
      currentTurnPlayer = players[playersOrderedByTurn[turnIndex]];
      break;
  }

  const doesTurnBelongToCurrentUser =
    currentUser && currentTurnPlayer && currentUser.playerId === currentTurnPlayer.playerId;

  const playerCount = _.values(players).length;

  const isCurrentPlayerHost = host && currentUser && host.playerId === currentUser.playerId;

  switch (status) {
    case 'WAITING_TO_START': {
      return (
        <Box direction="column" pad="large">
          <PregameRoomStatus
            gameId={gameId}
            isCurrentPlayerHost={isCurrentPlayerHost}
            playerCount={playerCount}
            startGame={startGame}
          />
          <WaitingRoom players={players} spectators={spectators} />
          <ChatBox gameId={gameId} currentUser={currentUser} messages={messages} />
        </Box>
      );
    }

    case 'IN_PROGRESS': {
      const currentUserTurnNumber =
        playersOrderedByTurn.findIndex((playerId) => currentUser.playerId === playerId) + 1;

      const isCurrentUserSpectator = currentUserTurnNumber === 0;

      let currentTurnDescription = '';
      if (turnIndex !== 0) {
        if (doesTurnBelongToCurrentUser) {
          currentTurnDescription = 'Choose a gift from pile or steal';
        } else {
          currentTurnDescription = 'to choose a new gift or steal...';
        }
      } else {
        if (doesTurnBelongToCurrentUser) {
          currentTurnDescription = 'You are first up! Pick a gift to unwrap';
        } else {
          currentTurnDescription = 'Person with #1 chooses a gift to unwrap';
        }
      }

      const currentTurnHeader = (
        <EgStickyBox direction="column" align="center" pad="small" gap="xsmall">
          <TurnHeading level="2" margin="none">
            {!doesTurnBelongToCurrentUser && `It is... `}
            {!doesTurnBelongToCurrentUser && (
              <span style={{ fontWeight: 'bold' }}>{currentTurnPlayer?.playerName}'s turn!</span>
            )}

            {doesTurnBelongToCurrentUser && (
              <span style={{ fontWeight: 'bold' }}>It is your turn!</span>
            )}
          </TurnHeading>
          <Heading level="4" margin="none" style={{ maxWidth: 'none', textAlign: 'center' }}>
            {currentTurnDescription}
          </Heading>

          <PlayerStatusView
            name={currentUser.playerName}
            isHost={currentUser.isHost}
            isSpectator={isCurrentUserSpectator}
            turnNumber={currentUserTurnNumber}
            playerCount={playerCount}
          />
        </EgStickyBox>
      );

      return (
        <Box direction="column" gap="small" style={{ width: '100%' }}>
          {currentTurnHeader}

          <GameContainer>
            <GiftPile>
              <LiveSession
                gameId={gameId}
                playersOrderedByTurn={playersOrderedByTurn}
                turnIndex={turnIndex}
                currentUser={currentUser}
                giftPile={giftPile}
                currentTurnPlayer={currentTurnPlayer}
                doesTurnBelongToCurrentUser={doesTurnBelongToCurrentUser}
                unwrappedGifts={unwrappedGifts}
                getPlayerNameById={getPlayerNameById}
              />
            </GiftPile>

            <Box direction="column" gap="medium" style={{ maxWidth: '720px', width: '100%' }}>
              <ChatBox gameId={gameId} currentUser={currentUser} messages={messages} />
              <WhosGotWhat
                status={status}
                gameId={gameId}
                currentUser={currentUser}
                players={players}
                unwrappedGifts={unwrappedGifts}
                playersOrderedByTurn={playersOrderedByTurn}
                doesTurnBelongToCurrentUser={doesTurnBelongToCurrentUser}
              />
            </Box>
          </GameContainer>
        </Box>
      );
    }

    case 'FINAL_ROUND':
    case 'STEAL_ROUND': {
      const currentUserTurnNumber =
        playersOrderedByTurn.findIndex((playerId) => currentUser.playerId === playerId) + 1;

      const isCurrentUserSpectator = currentUserTurnNumber === 0;

      let currentTurnDescription = '';
      if (doesTurnBelongToCurrentUser) {
        if (status === 'STEAL_ROUND') {
          currentTurnDescription = 'Choose a gift from the pile or steal';
        } else {
          currentTurnDescription = 'Either steal a gift OR keep your current gift to end the game!';
        }
      } else {
        if (status === 'STEAL_ROUND') {
          currentTurnDescription = 'to choose a new gift or steal...';
        } else {
          currentTurnDescription = 'to either steal a gift OR keep their gift to end the game...';
        }
      }

      const currentTurnHeader = (
        <EgStickyBox direction="column" align="center" pad="small">
          <TurnHeading level="2" margin="none">
            {status === 'STEAL_ROUND' ? (
              `Steal round in progress!`
            ) : (
              <span style={{ color: COLORS.lightTeal }}>Final round in progress!</span>
            )}{' '}
            {status === 'STEAL_ROUND' && (
              <span role="img" aria-label="Steal round smirking cat icon">
                😼
              </span>
            )}
          </TurnHeading>
          <Heading level="3" margin="small" textAlign="center">
            {!doesTurnBelongToCurrentUser && `It is `}
            {!doesTurnBelongToCurrentUser && (
              <span style={{ color: COLORS.primaryBrand }}>{currentTurnPlayer?.playerName}</span>
            )}
            {!doesTurnBelongToCurrentUser && `'s turn`}

            {doesTurnBelongToCurrentUser && (
              <span style={{ color: COLORS.primaryBrand }}>It is your turn!</span>
            )}
          </Heading>

          <Heading level="4" margin="none" style={{ maxWidth: 'none', textAlign: 'center' }}>
            {currentTurnDescription}
          </Heading>

          <Box direction="column" align="center" style={{ marginTop: 12, marginBottom: 12 }}>
            {status === 'FINAL_ROUND' && (doesTurnBelongToCurrentUser || currentUser.isHost) && (
              <EgButton
                primary
                type="button"
                onClick={endGame}
                label="Decline to steal + end the game"
              />
            )}
          </Box>

          <PlayerStatusView
            name={currentUser.playerName}
            isHost={currentUser.isHost}
            isSpectator={isCurrentUserSpectator}
            turnNumber={currentUserTurnNumber}
            playerCount={playerCount}
          />
        </EgStickyBox>
      );

      return (
        <Box direction="column" gap="small" style={{ width: '100%' }}>
          {currentTurnHeader}

          <GameContainer>
            <GiftPile>
              <LiveSession
                gameId={gameId}
                playersOrderedByTurn={playersOrderedByTurn}
                turnIndex={turnIndex}
                currentUser={currentUser}
                giftPile={giftPile}
                currentTurnPlayer={currentTurnPlayer}
                doesTurnBelongToCurrentUser={doesTurnBelongToCurrentUser}
                unwrappedGifts={unwrappedGifts}
                getPlayerNameById={getPlayerNameById}
              />
            </GiftPile>

            <Box direction="column" gap="medium" style={{ maxWidth: '720px', width: '100%' }}>
              <ChatBox gameId={gameId} currentUser={currentUser} messages={messages} />
              <WhosGotWhat
                status={status}
                gameId={gameId}
                currentUser={currentUser}
                players={players}
                unwrappedGifts={unwrappedGifts}
                playersOrderedByTurn={playersOrderedByTurn}
                doesTurnBelongToCurrentUser={doesTurnBelongToCurrentUser}
              />
            </Box>
          </GameContainer>
        </Box>
      );
    }

    case 'GAME_OVER': {
      return (
        <Box
          direction="column"
          pad="large"
          gap="medium"
          style={{ maxWidth: '720px', width: '100%' }}
        >
          <EgCappedHeading level="1" textAlign="center" margin="none">
            Game has ended!
          </EgCappedHeading>

          <ChristmasTreeIcon />

          <FinalGiftStandings
            players={players}
            unwrappedGifts={unwrappedGifts}
            playersOrderedByTurn={playersOrderedByTurn}
            currentUser={currentUser}
          />

          <WhosGotWhat
            status={status}
            gameId={gameId}
            currentUser={currentUser}
            players={players}
            unwrappedGifts={unwrappedGifts}
            playersOrderedByTurn={playersOrderedByTurn}
            doesTurnBelongToCurrentUser={doesTurnBelongToCurrentUser}
          />

          <ChatBox gameId={gameId} currentUser={currentUser} messages={messages} />
        </Box>
      );
    }

    default:
      return (
        <Box direction="column" align="center" pad="large">
          Loading...
        </Box>
      );
  }
};

export default Game;
