import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { Box, Heading } from 'grommet';

import { COLORS } from 'src/styles/Colors';

const Table = styled.table({
  borderCollapse: 'collapse',
  width: '100%',
});

const Th = styled.th({
  padding: '6px 12px',
  fontWeight: 200,
  textAlign: 'left',
  borderBottom: '1px dashed black',
});

const Tr = styled.tr({});

const Td = styled.td({
  verticalAlign: 'center',
  padding: '6px 12px',
});

const FinalGiftStandings = ({ players, unwrappedGifts, playersOrderedByTurn, currentUser }) => {
  // filter players down by who's gotten gifts so far, and sort
  // by turn order
  const playersWhoGotGifts = _.sortBy(
    _.filter(players, ({ gottenGiftId }) => gottenGiftId),
    ({ playerId }) => playersOrderedByTurn.findIndex((id) => id === playerId),
  );

  return (
    <Box direction="column" align="center" gap="xsmall">
      <Heading level="3" margin="none" style={{ textTransform: 'uppercase', fontWeight: 800 }}>
        Final Gift Standings
      </Heading>
      <Heading level="4" margin="none" style={{ maxWidth: 'none' }}>
        Please reach out to your gift's recipient and mail them your gift!
      </Heading>
      <Table>
        <thead>
          <Tr>
            <Th>Giftgiver</Th>
            <Th>Should Send Gift</Th>
            <Th>to Recipient</Th>
          </Tr>
        </thead>
        <tbody>
          {playersWhoGotGifts.map(({ playerName, gottenGiftId }) => {
            const giftData =
              !_.isEmpty(unwrappedGifts) && gottenGiftId && unwrappedGifts[gottenGiftId];

            return (
              <Tr
                key={gottenGiftId}
                style={
                  currentUser?.playerName === players[giftData?.playerId].playerName
                    ? {
                        background: COLORS.ultraLightTeal,
                        fontWeight: 'bold',
                      }
                    : {}
                }
              >
                <Td>{giftData && players[giftData.playerId].playerName}</Td>
                <Td>{giftData && giftData.giftTitle.toUpperCase()}</Td>
                <Td>{playerName}</Td>
              </Tr>
            );
          })}
        </tbody>
      </Table>
    </Box>
  );
};

export default FinalGiftStandings;
