import React, { useState } from 'react';
import Modal from 'styled-react-modal';

import { Box } from 'grommet';
import { IGift } from 'src/interfaces/IGift';
import { EgButtonFixedWidth, EgButton, EgTitleTextWrapping } from 'src/components/EgComponents';

const StyledModal = Modal.styled`
  width: 35rem;
  padding: 30px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 10px;
`;

interface GiftInfoProps {
  gift: IGift;
  playerName: string;
  playerId: string;
}

interface StealGiftFunc {
  (giftInfoProps: GiftInfoProps): void;
}

interface ActionFunc {
  (playerName: string, giftTitle: string): void;
}

interface StealGiftModalProps {
  gift: IGift;
  playerName: string;
  playerId: string;
  currentUser: any;
  primaryAction: StealGiftFunc;
  openAction: ActionFunc;
  cancelAction: ActionFunc;
}

const StealGiftModal = (props: StealGiftModalProps) => {
  const {
    gift,
    playerName,
    playerId,
    currentUser,
    primaryAction,
    openAction,
    cancelAction,
  } = props;
  const { giftTitle, giftUrl } = gift;

  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
    openAction(playerName, giftTitle);
  };

  const closeModal = () => {
    setIsOpen(false);
    cancelAction(playerName, giftTitle);
  };

  const giftTitleOrLink = giftUrl ? (
    <a href={giftUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
      <EgTitleTextWrapping>{giftTitle}</EgTitleTextWrapping>
    </a>
  ) : (
    <p>
      <EgTitleTextWrapping>{giftTitle}</EgTitleTextWrapping>
    </p>
  );

  const modalBody = (
    <Box direction="column" align="center" className="modal-body" style={{ marginBottom: 12 }}>
      <p style={{ lineHeight: 1.5 }}>Are you sure you want to steal {playerName}'s gift?</p>
      {giftTitleOrLink}
    </Box>
  );

  return (
    <div className="modal-component">
      <EgButton
        label="STEAL GIFT?"
        disabled={currentUser.playerId === playerId}
        onClick={openModal}
        style={{ width: 120, paddingLeft: 10, paddingRight: 10 }}
      />
      <StyledModal isOpen={isOpen} onBackgroundClick={closeModal} onEscapeKeydown={closeModal}>
        {modalBody}

        <div className="modal-confirm btn">
          <EgButtonFixedWidth
            secondary
            label="NEVER MIND"
            onClick={closeModal}
            style={{ margin: '10px' }}
          />
          <EgButtonFixedWidth
            primary
            label="STEAL GIFT!"
            onClick={() => primaryAction({ gift, playerName, playerId })}
            style={{ margin: '10px' }}
          />
        </div>
      </StyledModal>
    </div>
  );
};

export default StealGiftModal;
