import React from 'react';

import TreeGif from '../assets/white-elephant-2020-tree.gif';

export const ChristmasTreeIcon = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <img src={TreeGif} width={250} alt="Christmas tree with lights" />
  </div>
);
