export const buttonFont = {
  fontSize: '0.9em',
  fontWeight: '600',
};

export const buttonFixedWidth = {
  width: '200px',
  height: '42px',
  borderRadius: 8,
};

export const headerLetterSpacing = {
  letterSpacing: 3,
};

export const boldedUppercase = {
  fontWeight: 'bold',
  textTransform: 'uppercase',
};

export const textBreakAndWrap = {
  wordWrap: 'break-word',
  wordBreak: 'break-word',
};
