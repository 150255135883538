import _ from 'lodash';

import gift1Svg from 'src/assets/gift1.svg';
import gift2Svg from 'src/assets/gift2.svg';
import gift3Svg from 'src/assets/gift3.svg';
import gift4Svg from 'src/assets/gift4.svg';
import gift5Svg from 'src/assets/gift5.svg';
import gift6Svg from 'src/assets/gift6.svg';
import gift7Svg from 'src/assets/gift7.svg';
import gift8Svg from 'src/assets/gift8.svg';
import gift9Svg from 'src/assets/gift9.svg';
import gift10Svg from 'src/assets/gift10.svg';
import gift11Svg from 'src/assets/gift11.svg';
import gift12Svg from 'src/assets/gift12.svg';
import gift13Svg from 'src/assets/gift13.svg';
import gift14Svg from 'src/assets/gift14.svg';
import gift15Svg from 'src/assets/gift15.svg';
import gift16Svg from 'src/assets/gift16.svg';
import gift17Svg from 'src/assets/gift17.svg';
import gift18Svg from 'src/assets/gift18.svg';
import gift19Svg from 'src/assets/gift19.svg';
import gift20Svg from 'src/assets/gift20.svg';
import gift21Svg from 'src/assets/gift21.svg';
import gift22Svg from 'src/assets/gift22.svg';
import gift23Svg from 'src/assets/gift23.svg';
import gift24Svg from 'src/assets/gift24.svg';
import gift25Svg from 'src/assets/gift25.svg';

export const isValidUrl = (url: string) => {
  const regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  const isValid = regex.test(url);
  return isValid;
};

// TODO: DEPRECATE
// https://stackoverflow.com/a/16533568/10957842
function djb2(str) {
  let hash = 5381;
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) + hash + str.charCodeAt(i); /* hash * 33 + c */
  }
  return hash;
}

export function hashStringToColor(str) {
  const hash = djb2(str);
  const r = (hash & 0xff0000) >> 16;
  const g = (hash & 0x00ff00) >> 8;
  const b = hash & 0x0000ff;
  return (
    '#' +
    ('0' + r.toString(16)).substr(-2) +
    ('0' + g.toString(16)).substr(-2) +
    ('0' + b.toString(16)).substr(-2)
  );
}

const letters = Array.from('abcdefghijklmnopqrstuvwxyz'.toUpperCase());

export function generateLetterCode(length: number = 4): string {
  let code = '';
  let i = length;

  while (i > 0) {
    code += _.sample(letters);
    i -= 1;
  }
  return code;
}

// TODO: DEPRECATE
// https://stackoverflow.com/a/11868398
export function getContrastYIQ(hexcolor) {
  hexcolor = hexcolor.replace('#', '');
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
}

export const GIFTS_CONFIGS = [
  {
    svg: gift9Svg,
    w: 59,
    h: 150,
  },
  {
    svg: gift18Svg,
    w: 95,
    h: 77,
  },
  {
    svg: gift19Svg,
    w: 95,
    h: 38,
  },
  {
    svg: gift4Svg,
    w: 95,
    h: 106,
  },
  {
    svg: gift23Svg,
    w: 95,
    h: 75,
  },
  {
    svg: gift1Svg,
    w: 95,
    h: 83,
  },
  {
    svg: gift16Svg,
    w: 95,
    h: 95,
  },
  {
    svg: gift14Svg,
    w: 95,
    h: 71,
  },
  {
    svg: gift13Svg,
    w: 95,
    h: 44,
  },
  {
    svg: gift3Svg,
    w: 95,
    h: 101,
  },
  {
    svg: gift8Svg,
    w: 72,
    h: 150,
  },
  {
    svg: gift5Svg,
    w: 95,
    h: 58,
  },
  {
    svg: gift11Svg,
    w: 95,
    h: 58,
  },
  {
    svg: gift10Svg,
    w: 95,
    h: 107,
  },
  {
    svg: gift6Svg,
    w: 95,
    h: 103,
  },
  {
    svg: gift22Svg,
    w: 95,
    h: 83,
  },
  {
    svg: gift2Svg,
    w: 95,
    h: 141,
  },
  {
    svg: gift24Svg,
    w: 95,
    h: 49,
  },
  {
    svg: gift25Svg,
    w: 95,
    h: 103,
  },
  {
    svg: gift7Svg,
    w: 95,
    h: 63,
  },
  {
    svg: gift20Svg,
    w: 95,
    h: 50,
  },
  {
    svg: gift12Svg,
    w: 95,
    h: 75,
  },
  {
    svg: gift21Svg,
    w: 95,
    h: 141,
  },
  {
    svg: gift17Svg,
    w: 95,
    h: 135,
  },
  {
    svg: gift15Svg,
    w: 95,
    h: 38,
  },
];
