import React from 'react';
import { Box } from 'grommet';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { EgHowToPlayH2, EgButtonFixedWidth } from 'src/components/EgComponents';

const ListItem = styled.li({
  marginBottom: 20,
});
const RulesParagraph = styled.p({
  fontSize: 18,
  lineHeight: '24px',
  maxWidth: 700,
  width: '100%',
  marginTop: 0,
});
const BoldHighlight = styled.span({
  fontWeight: 600,
});
const SubText = styled.p({
  fontSize: 16,
  lineHeight: '20px',
});

const HowToPlay = () => {
  const history = useHistory();

  return (
    <Box direction="column" align="center" pad="large">
      <div
        style={{
          fontSize: 48,
          letterSpacing: 7,
          textTransform: 'uppercase',
          fontWeight: 600,
          marginBottom: 12,
        }}
      >
        How
        <span
          style={{
            display: 'inline-block',
            position: 'relative',
            top: -15,
            fontSize: 25,
            borderBottom: '4px solid black',
            marginRight: 23,
            marginLeft: 15,
            height: 45,
            width: 40,
            fontWeight: 900,
            letterSpacing: 5,
            lineHeight: 2,
          }}
        >
          To
        </span>
        Play
      </div>

      <EgHowToPlayH2 level="2" margin="small">
        Game Rules
      </EgHowToPlayH2>
      <RulesParagraph>
        <ol style={{ paddingInlineStart: '22px' }}>
          <ListItem>
            <RulesParagraph>Each player brings one gift.</RulesParagraph>
            <RulesParagraph>
              Since we are playing virtually,{' '}
              <BoldHighlight>you do not need to wrap or buy the gift beforehand</BoldHighlight>,{' '}
              especially if you are buying it online.
            </RulesParagraph>
            <RulesParagraph>
              For the purpose of the game, enter the name of the gift and a store link* when
              joining. Other players will not be able to see what your gift is, unless it is chosen
              and unwrapped during the game.
              <SubText>
                * Entering a store URL is optional as the gift doesn’t have to be online but it may
                be easier to have it ordered from an online store so it can ship to your recipient
                directly. In case your gift is something hand-made or something you cannot find
                online, filling out a link to your gift is not required for joining to play.
              </SubText>
            </RulesParagraph>
          </ListItem>
          <ListItem>
            Once the host starts the game, all players are assigned a random number to determine
            what turn order they will go in. Players who designate themselves as spectators do not
            get a turn. Once a game has started, no more players can be added mid-game.
          </ListItem>
          <ListItem>Each participant is able to see the gift pile.</ListItem>
          <ListItem>Player #1 starts off by choosing a gift from the pile to unwrap.</ListItem>
          <ListItem>
            The following players can either{' '}
            <BoldHighlight>choose a new gift from the pile</BoldHighlight> or{' '}
            <BoldHighlight>steal a previous gift</BoldHighlight> out of the ones that were already
            opened.
          </ListItem>
          <ListItem>
            <BoldHighlight>Anyone who gets their gift stolen </BoldHighlight>also will have the same
            choice of choosing a new gift or stealing a previous one.
          </ListItem>
          <ListItem>
            After all players have had their turn,{' '}
            <BoldHighlight>
              player #1 gets a chance to steal a gift from all previously opened gifts
            </BoldHighlight>
            . Whoever gets their gift stolen has a chance to steal someone else's. When someone
            declines to steal a gift, the game ends.
          </ListItem>
        </ol>
      </RulesParagraph>

      <EgHowToPlayH2 level="2" margin="small">
        Steal Round Restrictions
      </EgHowToPlayH2>
      <RulesParagraph>
        <ol style={{ paddingInlineStart: '22px' }}>
          <ListItem>
            No direct steal-backs! After you've had your gift stolen, you cannot immediately steal
            it back from the person who just took it from you.
          </ListItem>
          <ListItem>
            The <BoldHighlight> maximum number of times a gift can be stolen is 3</BoldHighlight>.
          </ListItem>
        </ol>
      </RulesParagraph>

      <EgHowToPlayH2 level="2" margin="small">
        Gift Exchange Logistics
      </EgHowToPlayH2>
      <RulesParagraph>
        At the end of the game, all players will be able to review who is the recipient of their
        gift.{' '}
        <BoldHighlight>
          It is the player's responsibility to send their gift and communicate with the recipient{' '}
        </BoldHighlight>
        on how the gift is to be delivered to them (shipping, drop off, etc).
      </RulesParagraph>

      <EgButtonFixedWidth
        primary
        type="button"
        label="GO BACK"
        onClick={() => history.push('/')}
        style={{ marginTop: 24 }}
      />
    </Box>
  );
};

export default HowToPlay;
