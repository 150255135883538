import React, { useRef, useEffect, useState } from 'react';
import _ from 'lodash';

import { IPlayer } from 'src/interfaces/IPlayer';
import { IGift } from 'src/interfaces/IGift';

import GrowingPacker from './packer.growing';
import { COLORS } from 'src/styles/Colors';

interface GiftBlock {
  giftId: string;
  w: number;
  h: number;
  color: string;
}

interface ModifiedGiftBlock {
  giftId: string;
  w: number;
  h: number;
  fit: {
    x: number;
    y: number;
  };
  color: string;
}

interface HandleGiftClick {
  (gift: ModifiedGiftBlock): void;
}

interface ContainerProps {
  gifts: GiftBlock[];
  unwrappedGifts: { [keyName: string]: IGift };
  handleGiftClick: HandleGiftClick;
  shouldDisableClick: boolean;
  currentUser: IPlayer;
}

interface GiftsPileProps {
  blocks: ModifiedGiftBlock[];
  unwrappedGifts: { [keyName: string]: IGift };
  canvasHeight: number;
  handleGiftClick: HandleGiftClick;
  shouldDisableClick: boolean;
  currentUser: IPlayer;
}

const FIXED_WIDTH = 300;

const Container = (props: ContainerProps) => {
  const { gifts, unwrappedGifts, handleGiftClick, shouldDisableClick, currentUser } = props;

  const [blocks, setBlocks] = useState([]);
  const [canvasHeight, setCanvasHeight] = useState(0);

  useEffect(() => {
    const copyBlocks = _.cloneDeep(gifts);
    // ! normally we'd need to sort largest to smallest
    // ! if we weren't using growing packer with infinite height;
    // ! otherwise, there's a chance a gift wouldn't be able to be
    // ! packed into the layout
    // copyBlocks.sort((a, b) => Math.max(b.w, b.h) - Math.max(a.w, a.h));

    const startingHeight = copyBlocks.length > 0 ? copyBlocks[0].h : 0;

    const packer = new GrowingPacker({
      fixedWidth: FIXED_WIDTH,
      startingHeight,
    });

    packer.fit(copyBlocks);

    setBlocks(copyBlocks);
    setCanvasHeight(packer.root.h);
  }, []);

  return (
    <GiftsPile
      blocks={blocks}
      unwrappedGifts={unwrappedGifts}
      canvasHeight={canvasHeight}
      handleGiftClick={handleGiftClick}
      shouldDisableClick={shouldDisableClick}
      currentUser={currentUser}
    />
  );
};

const GiftsPile = (props: GiftsPileProps) => {
  const {
    blocks,
    unwrappedGifts,
    canvasHeight,
    handleGiftClick,
    shouldDisableClick,
    currentUser,
  } = props;

  const canvasRef = useRef<HTMLCanvasElement>(document.createElement('canvas'));

  const drawImage = (context, block) => {
    const imageObj = new Image();
    imageObj.src = block.svg;
    imageObj.onload = function () {
      context.drawImage(imageObj, block.fit.x, block.fit.y, block.w, block.h);
    };

    if (currentUser.giftId === block.giftId) {
      context.lineWidth = 2;
      context.strokeStyle = COLORS.lightTeal;
      context.strokeRect(block.fit.x, block.fit.y, block.w, block.h);
    }
  };

  const drawRectPath = (context, block) => {
    context.beginPath();
    context.rect(block.fit.x, block.fit.y, block.w, block.h);
  };

  const drawBlocks = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d')!;

    canvas.height = canvasHeight;

    context.clearRect(0, 0, canvas.width, canvas.height);

    for (let n = 0; n < blocks.length; n++) {
      const block = blocks[n];

      if (block.fit) {
        if (!(block.giftId in unwrappedGifts)) {
          drawImage(context, block);
        }
      }
    }
  };

  useEffect(() => {
    drawBlocks();
  }, [blocks, unwrappedGifts]);

  const handleClick = (e) => {
    if (canvasRef) {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d')!;

      const rect = canvas.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      for (const block of blocks) {
        if (block.fit && !(block.giftId in unwrappedGifts)) {
          drawRectPath(context, block);

          if (context.isPointInPath(x, y)) {
            handleGiftClick(block);
          }
        }
      }
    }
  };

  // https://stackoverflow.com/a/29308863/10957842
  const onMouseMove = (e) => {
    if (canvasRef) {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d')!;

      const rect = canvas.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      let hitBlock = false;

      for (const block of blocks) {
        if (block.fit && !(block.giftId in unwrappedGifts)) {
          drawRectPath(context, block);

          if (context.isPointInPath(x, y)) {
            // context.strokeStyle = 'red';
            // context.setLineDash([6]);
            // context.strokeRect(block.fit.x, block.fit.y, block.w, block.h);
            hitBlock = true;
          } else {
            // context.strokeStyle = 'white';
            // context.setLineDash([6]);
            // context.strokeRect(block.fit.x, block.fit.y, block.w, block.h);
            // if (currentUser.giftId === block.giftId) {
            //   context.lineWidth = 2;
            //   context.strokeStyle = 'blue';
            //   context.strokeRect(block.fit.x, block.fit.y, block.w, block.h);
            // }
          }
        }
      }

      canvas.style.cursor = hitBlock ? (shouldDisableClick ? 'not-allowed' : 'pointer') : 'auto';
    }
  };

  return (
    <canvas
      onMouseOut={drawBlocks}
      onMouseMove={onMouseMove}
      onClick={handleClick}
      style={{ width: FIXED_WIDTH }} // consider no maxHeight if mobile
      width={FIXED_WIDTH}
      ref={canvasRef}
    />
  );
};

export default Container;
