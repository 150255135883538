import React from 'react';
import Modal from 'styled-react-modal';

import { Box } from 'grommet';
import { EgButtonFixedWidth } from 'src/components/EgComponents';
import { IPlayer } from 'src/interfaces/IPlayer';

const StyledModal = Modal.styled`
  width: 35rem;
  padding: 30px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 10px;
`;

interface PersistGiftSelectionFunc {
  (giftId: string): void;
}

interface ActionFunc {
  (): void;
}

interface Props {
  isOpen: boolean;
  currentUser: IPlayer;
  titleText: string;
  primaryButtonLabel: string;
  secondaryButtonLabel?: string;
  giftId: string;
  primaryAction: PersistGiftSelectionFunc;
  openAction?: ActionFunc;
  cancelAction: ActionFunc;
}

const UnwrapGiftModal = (props: Props) => {
  const { isOpen, cancelAction, titleText, giftId, primaryAction } = props;

  const closeModal = () => {
    cancelAction();
  };

  const modalBody = (
    <Box direction="column" className="modal-body" style={{ marginBottom: 12 }}>
      <p style={{ lineHeight: 1.5 }}>{titleText}</p>
    </Box>
  );

  return (
    <StyledModal isOpen={isOpen} onBackgroundClick={closeModal} onEscapeKeydown={closeModal}>
      {modalBody}

      <div>
        <EgButtonFixedWidth
          secondary
          label="NEVER MIND"
          onClick={closeModal}
          style={{ margin: '10px' }}
        />

        <EgButtonFixedWidth
          primary
          label={'YES!'}
          onClick={() => primaryAction(giftId)}
          style={{ margin: '10px' }}
        />
      </div>
    </StyledModal>
  );
};

export default UnwrapGiftModal;
