import { Box, Button, TextInput, Heading } from 'grommet';
import styled from 'styled-components';
import { COLORS } from 'src/styles/Colors';
import {
  buttonFont,
  buttonFixedWidth,
  headerLetterSpacing,
  boldedUppercase,
  textBreakAndWrap,
} from 'src/styles/CommonStyles';

export const EgCappedHeading = styled(Heading)({
  textTransform: 'uppercase',
  ...headerLetterSpacing,
});

export const EgButton = styled(Button)({
  borderRadius: 10,
  ...buttonFont,
});

export const EgButtonFixedWidth = styled(Button)({
  ...buttonFixedWidth,
  ...buttonFont,
});

export const EgBackButton = styled(Button)({
  ...buttonFixedWidth,
  ...buttonFont,
  background: 'white',
  color: 'black',
  fontWeight: 400,
  fontStyle: 'italic',
  border: 'none',
});

export const EgTextInput = styled(TextInput)({
  border: '3px solid black',
  background: COLORS.textFieldBg,
  height: 42,
  borderRadius: 7,
  fontSize: 18,
  fontWeight: 500,
});

export const EgFormTextInput = styled(EgTextInput)({
  height: 54,
});

export const EgGameH3 = styled(Heading)({
  padding: '8px 14px',
  textTransform: 'uppercase',
  fontWeight: 800,
});

export const EgGameBox = styled(Box)({
  border: '3px solid black',
  borderRadius: '8px',
});

export const EgStickyBox = styled(Box)({
  background: 'white',
  position: 'sticky',
  top: 0,
  padding: '18px 20px 22px',
  zIndex: 10,
  boxShadow: '0px -3px 22px -2px rgba(0, 0, 0, 0.8)',

  '@media (max-width: 521px)': {
    position: 'static',
  },
});

export const EgHowToPlayH2 = styled(Heading)({
  fontSize: 24,
  textTransform: 'uppercase',
  ...headerLetterSpacing,
});

export const EgTitleTextWrapping = styled.span({
  ...boldedUppercase,
  ...textBreakAndWrap,
});
