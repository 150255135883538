import React from 'react';
import { Form, Box, FormField } from 'grommet';
import { useHistory } from 'react-router-dom';

import useForm from '../hooks/useForm';
import { EgButtonFixedWidth, EgFormTextInput, EgBackButton } from './EgComponents';
import { Spinner } from '../components/Spinner';

const EnterRoom = (props: any) => {
  const history = useHistory();

  const initialFormState = {
    roomCode: '',
  };

  const { formState, handleChange, handleSubmit } = useForm({
    initialFormState,
    onSubmit: props.submitRoom,
  });

  // View
  return (
    <Form id="enter-room" onSubmit={handleSubmit}>
      <Box direction="column" align="center">
        <Box direction="row" margin="medium">
          <FormField
            label="Room Code"
            margin="medium"
            error={props.hasRoomError ? 'Room does not exist.' : ''}
          >
            <EgFormTextInput
              focusIndicator={true}
              placeholder="Enter 4-letter code"
              value={formState.roomCode}
              onChange={handleChange}
              name="roomCode"
              maxLength="4"
            />
          </FormField>
        </Box>

        {props.isLoading && <Spinner />}

        {!props.isLoading && (
          <>
            <EgButtonFixedWidth
              primary
              type="submit"
              form="enter-room"
              label="JOIN"
              maxlength="4"
              disabled={formState.roomCode.length < 4}
              style={{ marginBottom: 12 }}
            />
            <EgBackButton secondary type="button" label="Back" onClick={() => history.push('/')} />
          </>
        )}
      </Box>
    </Form>
  );
};

export default EnterRoom;
